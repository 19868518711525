<!--
 * @Author: OCEAN.GZY
 * @Date: 2023-02-03 14:13:50
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2023-07-18 21:05:46
 * @FilePath: /ai-pm/src/components/FooterComponent.vue
 * @Description: 注释信息
-->
<template>
  <div class="footer">
    <el-divider></el-divider>
    <div class="foot-info">
      <div class="cp"><a href="https://oceaneyes.top">OCEAN ZY©2015</a></div>
      <div class="frind-url">
        <a href="https://beian.miit.gov.cn">工信部备案</a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.el-divider{
  border:1px dashed #ffffff46;
}
.foot-info{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-left: 20px;
  padding-bottom: 10px;
}

a{
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  line-height: 24px;
}
.frind-url{
  margin-left: 20px;
}
</style>
