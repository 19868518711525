<!--
 * @Author: OCEAN.GZY
 * @Date: 2023-07-18 14:14:58
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2023-08-08 16:35:26
 * @FilePath: /ai-pm/src/views/HomeNew.vue
 * @Description: 注释信息
-->
<template>
    <div class="home">
        <div class="github">
            <a href="https://github.com/OcaenEyes" target="_blank"><font-awesome-icon :icon="['fab', 'github']"
                    style="color: #fff;font-size:40px"></font-awesome-icon></a>
        </div>
        <div class="main">
            <div class="main-inner">
                <Search :sengines="engines" class="search"></Search>
                <el-divider content-position="left"></el-divider>
                <div class="websites">
                    <el-tabs class="websites-nav" v-model="activeName">
                        <el-tab-pane class="websites-item" v-for="(i, index) in websites" :key="index" :label="i.name"
                            :name="i.en_name">
                            <div v-if="i.web">
                                <Item :item="i"></Item>
                            </div>
                            <div v-else v-for="(j, id) in i.children" :item="j" :key="id">
                                <Item :item="j"></Item>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

            </div>
        </div>
        <Footer class="footer"></Footer>
    </div>
</template>

<script setup>
import Search from "../components/SearchComponent.vue"
import Footer from "../components/FooterComponent.vue"
import Engines from "../assets/data/engine.json"
import Websites from "../assets/data/data.json"
import { ref } from 'vue'
import Item from "../components/ItemComponent"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

library.add(faGithub)
const engines = ref(Engines.engine);
const websites = ref(Websites);
const activeName = ref('hotool');

// const handleClick = (e) => {
//   console.log(e)
// }

</script>

<style lang="less" scoped>
.home {
    // background-color:red;
    width: 100%;
    min-width: 800px;
    height: 100%;
    // background-image: url("https://source.unsplash.com/collection/wallpapers/2400X1600");
    background-image: url("https://images.unsplash.com/photo-1557933106-fcc80f11b3c5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyMDg4MDd8MHwxfHNlYXJjaHw0fHx3YWxsYXBlcnxlbnwwfHx8fDE2ODk2NzE1OTV8MA&ixlib=rb-4.0.3&q=80&w=1080");
    background-position: center, center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .github {
        position: absolute;
        right: 10px;
        top: 20px;
        width: 60px;
        height: 60px;
        // background-color: red;
    }

    .main {

        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        overflow: hidden;
        // background-color:red;

        .main-inner {
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            // background-color: red;
            height: 96%;
            width: 72%;
            min-width: 600px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            box-shadow: 2px 2px 10px 2px black;

            .search {
                width: 80%;
                // background-color:red ;
                min-width: 500px;
                margin-top: 20px;
                margin-left: auto;
                margin-right: auto;
            }

            .el-divider {
                width: 85%;
                height: 0.1%;
                min-width: 500px;
                // background-color:green ;
                margin-left: auto;
                margin-right: auto;
                border: 1px dotted #ffffff8b;
            }

            .websites {
                width: 85%;
                height: 72%;
                min-width: 500px;
                margin-left: auto;
                margin-right: auto;
                overflow-y: hidden;
                color: #ffffff !important;

                .websites-nav {
                    width: 100%;
                    font-weight: bolder;

                    .websites-item {
                        color: #ffffff;
                    }
                }
            }

            .websites:hover {
                overflow-y: scroll;
            }

            .websites::-webkit-scrollbar {
                /*滚动条整体样式*/
                width: 5px;
                /*高宽分别对应横竖滚动条的尺寸*/
                height: 1px;
            }

            .websites::-webkit-scrollbar-thumb {
                /*滚动条里面小方块*/
                border-radius: 10px;
                background-color: rgba(135, 207, 235, 0.631);
                background-image: -webkit-linear-gradient(45deg,
                        rgba(255, 255, 255, 0.2) 25%,
                        transparent 25%,
                        transparent 50%,
                        rgba(255, 255, 255, 0.2) 50%,
                        rgba(255, 255, 255, 0.2) 75%,
                        transparent 75%,
                        transparent);
            }

            .websites::-webkit-scrollbar-track {
                /*滚动条里面轨道*/
                box-shadow: inset 0 0 3px rgba(127, 127, 127, 0.2);
                border-radius: 10px;
                background: #ededed;
            }
        }


    }

    .footer {
        width: 62%;
        // background-color:green;
        min-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>

