<template>
  <div class="search">
    <el-col :xs="22" :sm="22" :md="24" :lg="24" :xl="24">
      <el-card>
        <div class="search-web">
          <el-tag type="info" v-for="(o, i) in sengines" :key="i" :class="{ engineAct: currentEngine.name == o.name }"
            @click="changeEngine(o)">{{ o.name }}</el-tag>
        </div>
        <div class="search-input">
          <el-col :span="18">
            <el-input :placeholder="'使用' + currentEngine.value + '搜索一下'" :prefix-icon="search" v-model="keywords"
              @keydown.enter="onSearch"></el-input>
          </el-col>
          <el-col :span="2">
            <el-button style="color: #000" @click="onSearch">搜索</el-button>
          </el-col>
        </div>
      </el-card>
    </el-col>
  </div>
</template>

<script>
import { Search } from '@element-plus/icons-vue'
import {markRaw} from 'vue'
export default {
  props: {
    sengines: Array,
  },
  // mounted() {
  //   console.log(this.$props.sengines);
  // },
  data() {
    return {
      currentEngine: this.$props.sengines[2],
      keywords: "",
      search: markRaw(Search)
    };
  },
  methods: {
    onSearch() {
      console.log("搜索被点击");
      window.open(this.currentEngine.href + this.keywords, "_blank");
      this.keywords = "";
    },
    changeEngine(engine) {
      this.currentEngine = engine;
      console.log(this.currentEngine);
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  flex-direction: column;
  padding:10px;
  --el-color-primary: #333 !important;
}

.el-card {
  border-radius: 10px;
  /* background-color:green; */
}

.search>>>.el-card__body {
  padding: 20px 20px 30px 20px;
}

.search-web {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

.search-web>>>.el-tag--info {
  border-radius: 20px;
  color: #000;
  font-size: 16px;
  margin: 0 8px;
  min-width: 44px;
}

.search-web>>>.el-tag--info:hover {
  font-weight: 800;
  border-color: #000;
  background-color: #000;
  color: #fff;
  border-bottom: 2pt double;
}

.search-web .engineAct {
  font-weight: 800;
  border-color: #000;
  background-color: #000;
  color: #fff;
  border-bottom: 2pt double;
}

.search-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  align-items: center;
}

.search-input>>>.el-input__inner {
  height: 50px;
  border: 1px solid rgb(243, 243, 243);
}

.search-input>>>.el-button {
  color: #000;
  border: 1px solid rgb(243, 243, 243);
  background-color: #fff;
}

.search-input>>>.el-button:hover {
  font-weight: 800;
  border-color: #000;
  background-color: #000;
  color: #fff !important;
}</style>