<template>
  <div class="items">
    <!-- <div class="web-title">
      <h4>
        <i class="fa fa-tags" :id="item.name"></i>{{
          item.name
        }}
      </h4>
    </div> -->
    <el-row class="web-items" :gutter="10">
      <el-col
        :xs="22"
        :sm="11"
        :md="6"
        :lg="6"
        :xl="4"
        v-for="(o, i) in item.web"
        :key="i"
        @click="openweb(o.url)"
      >
        <el-card
          shadow="hover"
          class="web-item"
          :body-style="{ display: 'flex', 'flex-direction': 'row' }"
        >
          <div class="item-pic">
            <img :src="o.logo" alt="" />
          </div>
          <el-tooltip effect="dark" :content="o.url" placement="top-end">
            <div class="item-desc">
              <a href="#"
                ><div class="title">{{ o.title }}</div></a
              >
              <div class="desc">{{ o.desc }}</div>
            </div>
          </el-tooltip>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    item: Object
  },
  data() {
    return {};
  },
  methods: {
    openweb(url) {
      console.log(url);
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.web-title {
  text-align: left;
  margin-left: 10px;
}
.web-items{
  margin-top: 16px;
}
.web-item {
  height: 88px !important;
  margin: 10px;
  border-radius: 6px;
  background-color: #fff;
  align-items: center;
  text-align: left;
  border: 1px solid rgb(243, 243, 243);
}
.item-pic img {
  object-fit: cover;
  border-radius: 18px;
  width: 36px;
  height: 36px;
}
.item-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 18px;
}
.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
}
.desc {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
  color: rgb(99, 99, 99);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* 限制在一个块元素显示的文本的行数 */
  /* -webkit-line-clamp 其实是一个不规范属性，使用了WebKit的CSS扩展属性，该方法适用于WebKit浏览器及移动端；*/
  -webkit-line-clamp: 2;
  /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-box-orient: vertical;
}
a {
  text-decoration: none;
  color: rgb(68, 68, 68);
}
.items {
  padding-left: auto;
  padding-right: auto;
}
</style>
