/*
 * @Descripttion: 
 * @version: V0.0.1
 * @Author: OCEAN.GZY
 * @Date: 2021-04-19 15:54:54
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2023-07-18 22:03:16
 */

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./assets/static/css/view.css"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';



createApp(App).component('font-awesome-icon', FontAwesomeIcon).use(router).use(store).use(ElementPlus).mount('#app')