/*
 * @Author: OCEAN.GZY
 * @Date: 2023-07-18 14:33:04
 * @LastEditors: OCEAN.GZY
 * @LastEditTime: 2023-07-18 14:33:46
 * @FilePath: /ai-pm/src/store/index.js
 * @Description: 注释信息
 */
import { createStore } from 'vuex'

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {

    }
})